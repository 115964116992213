#bio-panel {
    font-size: 1.3rem;

    #portrait {
        display: flex;
        flex-direction: row;
        
        #left {
            min-width: 0;
            width: 100%;
            height: auto;
            flex: 1 0 0;
            padding: 20px;
            align-self: center;
            border-radius: 25px;
        }

        #right {
            flex: 4 0 0;
        }
    }

    @media screen and (max-width: 500px) {
        #left {
            display: none;
        }
    }
}

.project-list {
    h2 {
        text-align: center;
        font-weight: normal;
        font-size: 1.6rem;
    }

    .projects {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 20px;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            grid-auto-rows: unset;
        }

        margin: 0;
        padding: 0;

        .project {
            list-style-type: none;
            display: block;
            background-color: #d8d8d8;
            border-radius: 7px;

            transition: transform 0.2s ease, box-shadow 0.2s ease;
            &:hover {
                box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.75);
                transform: scale(1.03);
                cursor: pointer;
            }    

            a {
                display: block;
                color: inherit;
                text-decoration: none;
                
                .hero {
                    width: 100%;
                    border-radius: 7px 7px 0 0;
                }
                
                .header {
                    padding: 15px 15px 0 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    h3 {
                        font-weight: 500;
                        margin: 0;
                        padding: 0;
                        flex: 1 1 0;
                        vertical-align: middle;
                        font-size: 1.3rem;

                        &.no-bold {
                            font-weight: normal;
                        }
                    }

                    img {
                        height: 25px;
                    }

                    &.no-description {
                        padding-bottom: 15px;
                    }    
                }

                .description {
                    padding: 0 15px 15px 15px;
                    margin-top: 10px;
                    font-size: 1.3rem;
                }
            }

            sub {
                // Make dates line up with each other
                font-variant-numeric: tabular-nums;

                padding-left: 10px;
            }
        }
    }

    .projects.single-width {
        grid-template-columns: 1fr;
    }

    a {
        display: block;
        color: inherit;
        text-decoration: none;

        .github {
            margin: 20px 30% 0 30%;
            @media screen and (max-width: 800px) {
                margin: 20px 0 0 0;
            }
            
            list-style-type: none;
            display: block;
            background-color: #d8d8d8;
            border-radius: 7px;
            padding: 10px;
            display: flex;

            transition: transform 0.2s ease, box-shadow 0.2s ease;
            &:hover {
                box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.75);
                transform: scale(1.03);
                cursor: pointer;
            }

            h3 {
                flex: 1 1 0;
                margin: 0;
                font-size: 1.3rem;
            }

            img {
                height: 25px;
            }
        }
    }
}
